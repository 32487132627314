<template>
    <div class="histogram-div" v-if="showHistogram">
        <Histogram :colors="colors" :extend="extend" :data="data"></Histogram>
    </div>
</template>

<script>
import Histogram from 'v-charts/lib/histogram.common'
export default {
    props:['extend','data'],
    components:{Histogram},
    data(){
        return{
            showHistogram:true,
            colors:['#61e9f1','#56a3d5', '#4b83d9','#e6d677','#cfa47c','#2fb56e','#599e1e','#7841ff','#1312d2','#b14140','#b4428c']
        }
    },
    watch:{
        histogramData(){
            this.showHistogram = false
            this.$nextTick(()=>{
                this.showHistogram = true
            })
        }  
    }   
}
</script>

<style lang="less" scoped>
.histogram-div{

}
</style>